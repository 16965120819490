import { HeroSlide } from './HeroSlide';
import { HeroSlider } from './HeroSlider';
import { Schema } from './Hero.schema';

export function Hero({ cms }) {
  const { section, slider, slides } = cms;
  const maxWidthContainerClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-lg-width)]';
  const fullBleedClass = section?.fullBleed ? '' : 'px-contained';
  const heightClassesDesktopXL =
    section?.desktop?.heightType === 'aspect-ratio'
      ? `${section?.desktopXL?.aspectRatio} ${section?.desktopXL?.minHeight} ${section?.desktopXL?.maxHeight}`
      : section?.desktopXL?.staticHeight || 'xl:h-[43.75rem]';
  const heightClassesDesktop =
    section?.desktop?.heightType === 'aspect-ratio'
      ? `${section?.desktop?.aspectRatio} ${section?.desktop?.minHeight} ${section?.desktop?.maxHeight}`
      : section?.desktop?.staticHeight || 'lg:h-[37.5rem]';
  const heightClassesTablet =
    section?.tablet?.heightType === 'aspect-ratio'
      ? `${section?.tablet?.aspectRatio} ${section?.tablet?.minHeight} ${section?.tablet?.maxHeight}`
      : section?.tablet?.staticHeight || 'md:max-lg:h-[35rem]';
  const heightClassesMobile =
    section?.mobile?.heightType === 'aspect-ratio'
      ? `${section?.mobile?.aspectRatio} ${section?.mobile?.minHeight} ${section?.mobile?.maxHeight}`
      : section?.mobile?.staticHeight || 'max-md:h-[31.25rem]';
  const heightContainerClasses = `${heightClassesMobile} ${heightClassesTablet} ${heightClassesDesktop} ${heightClassesDesktopXL}`;

  return (
    <div
      className={`${fullBleedClass} ${
        section?.verticalPadding ? 'py-contained' : ''
      }`}
    >
      <div
        className={`relative mx-auto flex w-full flex-col bg-offWhite ${heightContainerClasses} ${maxWidthContainerClass}`}
      >
        {slides?.length > 1 && (
          <HeroSlider
            aboveTheFold={section?.aboveTheFold}
            slider={slider}
            slides={slides}
            fullSizeContent={section?.fullSizeContent}
          />
        )}

        {slides?.length === 1 && (
          <HeroSlide
            aboveTheFold={section?.aboveTheFold}
            isActiveSlide
            isFirstSlide
            slide={slides[0]}
            fullSizeContent={section?.fullSizeContent}
          />
        )}
      </div>
    </div>
  );
}

Hero.displayName = 'Hero';
Hero.Schema = Schema;
