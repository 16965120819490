import { useCallback } from 'react';
import { useGlobalContext } from '../../contexts';

import { Image, Link, ContentPopUp } from '../../snippets';
import { Schema } from './ImagesGrid.schema';

export function ImagesGrid({ cms }) {
  const {
    actions: { openModal },
  } = useGlobalContext();

  const { images, textBlock, section } = cms;
  const maxWidthClass = section?.fullWidth
    ? 'max-w-none'
    : 'max-w-[var(--content-max-lg-width)]';
  const itemsPerRow = section?.itemsPerRow;
  const isImageSizeEmpty = !section?.imageSize;
  const imageSize = section?.imageSize;

  const handlePopUpClick = useCallback(() => {
    openModal(
      <ContentPopUp
        content={textBlock?.popUp}
        className="h-[calc(100%-2rem)] max-w-full max-h-full"
        extraClassName="max-h-full h-full"
      />
    );
  }, [textBlock?.popUp]);

  return (
    <div className={`${section?.fullBleed ? '' : 'px-contained py-contained'}`}>
      <ul
        className={`mx-auto grid ${itemsPerRow} ${maxWidthClass} ${!isImageSizeEmpty ? 'gap-2.5 md:gap-5' : ''}`}
      >
        {images?.slice(0, 64).map((item, index) => {
          return (
            <li key={index}>
              <div className={`relative ${isImageSizeEmpty ? 'bg-offWhite aspect-square' : ''}`}>
                <Link
                  aria-label={`Open ${item.link?.text}`}
                  href={item.link?.url}
                  newTab={item.link?.newTab}
                  {...(!isImageSizeEmpty ? { className: 'w-full h-full flex justify-center' } : {})}
                >
                  {item.image?.src && (
                    <Image
                      alt={item.alt}
                      {...(isImageSizeEmpty ? { fill: true } : { contain: true, width: imageSize, height: imageSize })}
                      sizes="(min-width: 768px) 25vw, 50vw"
                      src={item.image.src}
                    />
                  )}
                </Link>
              </div>
              {item.link?.text && (
                <Link
                  aria-label={`Open ${item.link.text}`}
                  className="block mt-4"
                  href={item.link.url}
                  newTab
                >
                  <h4 className="text-center text-lg lg:text-xl">{item.link.text}</h4>
                </Link>
              )}
            </li>
          );
        })}
        {(textBlock?.heading || textBlock?.text || textBlock?.link || textBlock?.popUp?.buttonText || textBlock?.popUp?.heading || textBlock?.popUp?.text || textBlock?.popUp?.html || textBlock?.popUp?.typeformId ) && (
          <li>
            <div className="flex flex-col justify-center items-center p-2.5">
              {textBlock?.heading && <h3 className="text-center text-title-h1">{textBlock.heading}</h3>}
              {textBlock?.text && <p className="text-center">{textBlock.text}</p>}
              {textBlock?.link && (
                <Link
                  aria-label={`Open ${textBlock.link.text}`}
                  className="mt-4"
                  href={textBlock.link.url}
                  newTab
                >
                  {textBlock.link.text}
                </Link>
              )}
              {(textBlock?.popUp?.buttonText || textBlock?.popUp?.heading || textBlock?.popUp?.text || textBlock?.popUp?.html || textBlock?.popUp?.typeformId) && (
                <button
                  className="btn-primary"
                  onClick={handlePopUpClick}
                  type="button"
                >
                  {textBlock.popUp.buttonText || 'Click Here'}
                </button>
              )}
            </div>
          </li>
        )}
      </ul>
    </div>
  );
}

ImagesGrid.displayName = 'ImagesGrid';
ImagesGrid.Schema = Schema;
